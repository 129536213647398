import React from "react";
import PageTitle from "@insightfulscience/smart-react/PageTitle";
import { useLocale } from "@insightfulscience/smart-react/i18";
import AppError from "@src/AppError";
import ErrorLayout from "@src/AppError/ErrorLayout";
import PageMeta from "@insightfulscience/smart-react/PageMeta";

const Error404 = () => {
	const [t] = useLocale("404-page");

	return (
		<>
			<PageMeta title={t("PAGE_META")} />
			<PageTitle title={t("PAGE_TITLE")} />
			<AppError error={{ code: 404 }} Layout={ErrorLayout.Pure} isAuthorized />
		</>
	);
};

export default Error404;
