import React from "react";
import ContentContainer from "@insightfulscience/atomic-react/r1/atoms/ContentContainer";
import Block from "@insightfulscience/atomic-react/Block";
import { publicConfig } from "../config";
import errorPropType from "./error-prop-type";

const ErrorDetails = ({ error }) => (
	<ContentContainer mx md={10} lg={8} center>
		<Block utility={{ radius: 6, bgColor: "black" }}>
			<pre className=" text-color-white px-5 py-5">{JSON.stringify(error, null, 2)}</pre>
		</Block>
	</ContentContainer>
);

ErrorDetails.propTypes = {
	error: errorPropType,
};

export default publicConfig.DEBUG_MODE ? ErrorDetails : () => null;
