import React from "react";
import { bool, node } from "prop-types";
import SimpleTemplate from "@insightfulscience/atomic-react/SimpleTemplate";
import Section from "@insightfulscience/atomic-react/Section";

import SiteLayout from "../SiteLayout";

/** @type {React.FC<{ children: React.ReactNode, extra: React.ReactNode }>} */
const ErrorLayout = ({ children, extra }) => (
	<SiteLayout isWrapper={false}>
		<SimpleTemplate>{children}</SimpleTemplate>
		{extra && <Section spacing={{ pt: 9, pb: 18, md: { pt: 9, pb: 18 } }}>{extra}</Section>}
	</SiteLayout>
);

ErrorLayout.propTypes = {
	children: node,
	extra: node,
};

/** @type {React.FC<{ children: React.ReactNode, extra: React.ReactNode, isAuthorized: boolean }>} */
const ErrorLayoutPure = ({ children, extra, isAuthorized }) => (
	<SiteLayout.Pure isAuthorized={isAuthorized} isWrapper={false}>
		<SimpleTemplate>{children}</SimpleTemplate>
		{extra && <Section spacing={{ pt: 9, pb: 18, md: { pt: 9, pb: 18 } }}>{extra}</Section>}
	</SiteLayout.Pure>
);

ErrorLayoutPure.propTypes = {
	...ErrorLayout.propTypes,
	isAuthorized: bool,
};

ErrorLayout.Pure = ErrorLayoutPure;

export default ErrorLayout;
